import { useEffect, useState } from 'react';
import { Col, Row, Button, Select, Typography, Space, notification } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { useAccount, useSignMessage, useWalletClient } from 'wagmi';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { BrowserProvider, JsonRpcSigner } from 'ethers';
import { GatewayProvider, useGateway, ButtonMode, IdentityButton } from '@civic/ethereum-gateway-react';
import { useNavigate } from 'react-router-dom';

const networkKey = process.env.REACT_APP_CIVIC_KEY;
const { Text } = Typography;

function Withdraw({ parentCallBack }) {
  const navigate = useNavigate();
  const { requestGatewayToken } = useGateway();
  const { gatewayStatus, gatewayToken } = useGateway();
  const [gatewayTextValue, setGatewayTextValue] = useState();
  const [inputStyleFin, setInputStyleFin] = useState({ textAlign: "start" });
  const [inputStyleMatic, setInputStyleMatic] = useState({ textAlign: "end" });
  const [finValue, setFinValue] = useState();
  const [ethValue, setEthValue] = useState();
  const { connector, address, isConnected } = useAccount();
  let chainId;

    if(connector){    connector.getChainId()
    .then(chainId => {
      console.log(chainId); 
      chainId = chainId;
    })
    .catch(error => {
      console.error('Error getting chainId:', error);
    }); }  
  const { data: walletClient } = useWalletClient({chainId});

  const [user, setUser] = useState();
  const [tokenBalance, setTokenBalance] = useState();
  const [withdrawData, setWithdrawData] = useState();
  const [api, contextHolder] = notification.useNotification();
  const { signMessage, isSuccess } = useSignMessage();
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const apikey = process.env.REACT_APP_API_KEY;

  const walletClientToSigner = (walletClient) => {
    const { account, chain, transport } = walletClient;
    const network = {
      chainId: chain?.id,
      name: chain?.name,
      ensAddress: chain?.contracts?.ensRegistry?.address,
    };
    const provider = new BrowserProvider(transport, network);
    return new JsonRpcSigner(provider, account?.address);
  };

  const Gateway = () => {
    if (walletClient) {
      const wsigner = walletClientToSigner(walletClient);
      const wallet = { address, signer: wsigner };
      getText();
      return (
        <GatewayProvider options={{ autoShowModal: true, logLevel: "debug" }} gatekeeperNetwork={networkKey} wallet={wallet}>
          <IdentityButton mode={ButtonMode.LIGHT} animation={true} />
                  </GatewayProvider>
      );
    }
  };

  const getUser = async () => {
    try {
      const res = await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${address?.toLowerCase()}`);
      setUser(res.data);
      if (!res.data) {
        openNotification('bottomLeft', "User not found", "error");
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    const getBalance = async () => {
      try {
        await getUser();
        const res = await axios.get(`https://mint.infinityvoid.io/gameApi/withdrawToken/byWalletAddress/${address?.toLowerCase()}`);
        if (res.data) {
          setWithdrawData(res.data);
          const balance = Number(res.data.balance);
          setTokenBalance(balance);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };
    if (isConnected) getBalance();
  }, [isConnected, address]);

  const getText = () => {
    setTimeout(() => {
      const activeTextId = document.querySelector('[data-testid="CIVIC_IDENTITY_BUTTON"]');
      if (activeTextId) {
        const activeText = activeTextId.textContent;
        setGatewayTextValue(activeText);
      }
    }, 10000);
  };

  useEffect(() => {
    if (isSuccess) {
      withdrawApprove();
    }
  }, [isSuccess]);

  const openNotification = (placement, message, type) => {
    api.open({
      type,
      message,
      description: '',
      placement,
    });
  };

  const withdrawApprove = async () => {
    try {
      const balance = tokenBalance - finValue;
      const updateItem = { balance, apikey };
      const res = await axios.put(`https://mint.infinityvoid.io/gameApi/withdrawToken/${withdrawData.id}`, updateItem);
      if (res.status === 200) {
        const newItem = {
          data: {
            walletAddress: address?.toLowerCase(),
            userId: user.id,
            token: "FINTOKEN",
            amount: finValue,
            status: "pending",
            apikey,
            transactionType: "Withdraw",
          },
        };
        const withdrawRes = await axios.post(`https://mint.infinityvoid.io/gameApi/depositToken`, newItem);
        if (withdrawRes.status === 200) {
          openNotification("bottom", "Withdraw Request Sent", "success");
          window.location.reload(true);
        }
      }
    } catch (error) {
      console.error('Error during withdrawal approval:', error);
    }
  };

  const WithDrawToken = async () => {
    if (ethValue !== undefined && withdrawData !== undefined) {
      const signMessageR = `Withdrawal ${finValue} Token`;
      await signMessage({ message: signMessageR });
    }
  };

  const finChange = (event) => {
    const value = event.target.value;
    if (value <= tokenBalance) {
      setFinValue(value);
      const ethValue = (0.0006 * value).toFixed(3);
      setEthValue(ethValue);
      const width = 100 + value.toString().length * 10;
      setInputStyleFin({ width: Math.min(width, 300) + 'px' });

      const fontSize = 42 - value.length * 1.5;
      setInputStyleMatic({ width: Math.min(width, 300) + 'px', textAlign: 'end', fontSize: fontSize + 'px' });
    } else {
      openNotification("bottom", "Amount not available", "error");
    }
  };

  return (
    <>
      {contextHolder}
      <Row gutter={24} style={{ marginTop: "10%", marginBottom: "10%" }}>
        <Col className="maticCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }}>
          <Space direction="vertical" size={"middle"}>
            <Text className="textParaHead">You'll withdraw</Text>
            <Space direction="horizontal">
              <Select disabled showArrow={false} labelInValue bordered={false} defaultValue={{ value: 'FINITY', label: 'TOKEN' }} />
              <input onChange={finChange} className="Input" placeholder="0.00" value={finValue} style={inputStyleMatic} />
            </Space>
            <Space direction="horizontal">
              <Text className="textPara">1 TOKEN =</Text>
              <input onChange={finChange} className="InputA" disabled placeholder="0.0006" style={{ width: "65px", padding: "8px" }} />
            </Space>
          </Space>
        </Col>
        <Col className="midCol" flex={{ xs: 4, sm: 4, md: 4, lg: 4 }} style={{ textAlign: "center" }}>
          {isMobile ? (
            <SwapOutlined rotate={270} onClick={() => parentCallBack("changeTab")} className="swapIcon" color="#ffffff" />
          ) : (
            <SwapOutlined onClick={() => parentCallBack("changeTab")} className="swapIcon" color="#ffffff" />
          )}
        </Col>
        <Col className="finCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }}>
          <Space direction="vertical" size={"middle"}>
            <Text className="textParaHead">You'll receive *</Text>
            <Space direction="horizontal" size={"small"}>
              <input disabled className="Input" placeholder="0.00" value={ethValue} style={inputStyleFin} />
              <Select disabled showArrow={false} bordered={false} labelInValue defaultValue={{ value: 'ETH', label: 'ETH' }} />
            </Space>
            <Space direction="horizontal">
              <Text className="textPara">1 ETH =</Text>
              <input onChange={finChange} className="InputA" disabled placeholder="1500 TOKEN" style={{ width: "120px", padding: "8px" }} />
            </Space>
          </Space>
        </Col>
      </Row>
      {gatewayTextValue !== "Active" ? (
        <Gateway />
      ) : (
        <>
          <Button onClick={() => WithDrawToken()} style={{ height: "40px", borderRadius: "20px" }}>
            Withdraw Amount
          </Button>
          <br />
          <Text className="textPara" style={{ fontSize: "12px", textAlign: "center" }}>
            *Transaction fees of 2.5%.
          </Text>
        </>
      )}
    </>
  );
}

export default Withdraw;
