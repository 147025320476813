import React, { useState } from "react";
import { useAccount } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button, Input, Typography, Checkbox, notification } from 'antd';
import axios from "axios";

const { Text } = Typography;

function CreateAccountPage({ parentCallBack }) {
  const navigate = useNavigate();
  const { address, isConnected } = useAccount();
  
  // States for form data
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to handle form submission
  const handleSubmit = async () => {
    if (!username || !fullName || !email) {
      notification.error({
        message: 'Form Error',
        description: 'Please fill all the fields.',
      });
      return;
    }

    if (!acceptTerms) {

      
      notification.error({
        message: 'Terms & Conditions',
        description: 'You must accept the Terms & Conditions and Privacy Policy.',
      });
      return;
    }
    
    setLoading(true);
    
    try {
      // Assuming you have an API to handle account creation
      const response = await axios.post('https://mint.infinityvoid.io/gameApi/gameUser', { 
        name: username, 
        fullName, 
        email, 
        walletAddress: address.toLowerCase() 
      });
      
      console.log(response);

      if (response.status === 200) {
        notification.success({
          message: 'Account Created',
          description: 'Your account has been created successfully!',
        });
        parentCallBack();
      } else {
        notification.error({
          message: 'Account Creation Failed',
          description: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Something went wrong. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
<>
  

    <Row gutter={24} style={{ marginTop: "2%", marginBottom: "10%" }}>
      <Text className='textParaHead' style={{ marginBottom: "20px" }}>Create Account</Text>

      <Col className='maticCol' flex={{ xs: 4, sm: 4, md: 4, lg: 4 }} style={{ textAlign: "center" }}>
        <div className="formCol">
          <Input
            className="custom-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            style={{ marginBottom: 10 }}
          />
          <Input
            className="custom-input"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full Name"
            style={{ marginBottom: 10 }}
          />
          <Input
            className="custom-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            type="email"
            style={{ marginBottom: 10 }}
          />

<Checkbox 
  checked={acceptTerms} 
  onChange={(e) => setAcceptTerms(e.target.checked)}
  style={{ marginBottom: 20, color: "white" }}
>
  I accept the 
  <a href="/terms-conditions" target="_blank" rel="noopener noreferrer" style={{ color: "#e53f71", marginLeft: 5 }}>
    Terms & Conditions &nbsp;
  </a> 
  and 
  <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "#e53f71", marginLeft: 5 }}>
    Privacy Policy
  </a>
</Checkbox>


          <Button
            style={{ color: "white", background: "#E53F71" }}
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={!acceptTerms} 
            block
          >
            Create Account
          </Button>
        </div>
      </Col>
    </Row>
    </>
  );
}

export default CreateAccountPage;
