import React from "react";
import { Layout,Row, Typography,Col, Collapse, List, Button, Space } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
const { Header } = Layout;

const { Title,Text, Paragraph } = Typography;
const { Panel } = Collapse;


const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '100%',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
  };




const TermsPage = () => {
  return (
    <Layout>

<Header style={headerStyle}>

<Row >

    
      <Col flex={2} className="navBarLogo">
  <Space.Compact direction='horizontal'>
    <a href = '/'>
    <img src = "./logoNew.jpg" style={{width:"64px"}} />
    </a>
    <Text style={{fontFamily:"Audiowide", fontSize:"24px",marginLeft:"8px",marginTop:"5px", fontWeight:"200px"}}>Liberaland Casino</Text>
   
</Space.Compact>
</Col>
</Row>
</Header>

      <Layout.Content style={{ marginLeft: "140px",marginRight: "140px",marginTop:"12px",marginBottom:"12px" }}>
        <Title level={2} style={{ textAlign: "center" }}>
          Terms and Conditions
        </Title>
        <Paragraph style={{ textAlign: "center", fontSize: "16px" }}>
          Effective Date: January 19, 2025
        </Paragraph>
        
        <Paragraph style={{ fontSize: "16px", marginTop: "20px" }}>
          Welcome to Liberland Casino ("we," "our," "us"). By using our website, mobile application, or any of our services (collectively, the "Services"), you agree to abide by the following Terms and Conditions ("Terms"). Please read them carefully. If you do not agree with any part of these Terms, you must refrain from using the Services.
        </Paragraph>

        <Collapse
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          bordered={false}
          defaultActiveKey={['1']}
          style={{ marginTop: "20px" }}
        >
          <Panel header="1. Eligibility" key="1">
            <List>
              <List.Item>• You must be at least 18 years old or of legal gambling age in your jurisdiction, whichever is greater, to use our Services.</List.Item>
              <List.Item>• It is your responsibility to ensure that online gambling is legal in your jurisdiction before registering or using our Services.</List.Item>
              <List.Item>• By accessing or using the Services, you confirm that you are not a resident of or located in a jurisdiction where online gambling is prohibited.</List.Item>
              <List.Item>• Do not use VPNs or any other technology to bypass security measures implemented by us.</List.Item>
            </List>
          </Panel>

          <Panel header="2. Account Registration" key="2">
            <List>
              <List.Item>• You must create an account to access the Services. All information provided during registration must be accurate and complete.</List.Item>
              <List.Item>• You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.</List.Item>
              <List.Item>• We reserve the right to suspend or terminate your account if we suspect any violation of these Terms or fraudulent activity.</List.Item>
            </List>
          </Panel>

          <Panel header="3. Cryptocurrency Usage" key="3">
            <List>
              <List.Item>• Liberland Casino exclusively accepts cryptocurrencies for deposits and withdrawals. Supported cryptocurrencies will be listed on our platform.</List.Item>
              <List.Item>• You are responsible for ensuring that any cryptocurrency transactions comply with applicable laws in your jurisdiction.</List.Item>
              <List.Item>• Cryptocurrency transactions are irreversible. Liberland Casino is not responsible for errors, including deposits sent to incorrect wallet addresses.</List.Item>
            </List>
          </Panel>

          <Panel header="4. Responsible Gaming" key="4">
            <List>
              <List.Item>• Liberland Casino promotes responsible gaming and offers tools to help you manage your gambling, including deposit limits, loss limits, and self-exclusion options. Please open a support ticket in our Discord server if you wish to be locked from entering the casino.</List.Item>
              <List.Item>• If you suspect you have a gambling problem, we encourage you to seek professional help and use our self-exclusion tools.</List.Item>
              <List.Item>• Gambling is intended as a form of entertainment, not a way to earn income. Never gamble more than you can afford to lose.</List.Item>
            </List>
            <Paragraph>
              Resources for gambling addiction:
              <List>
                <List.Item>• Gamblers Anonymous</List.Item>
              </List>
            </Paragraph>
          </Panel>

          <Panel header="5. Anti-Money Laundering (AML) and Know Your Customer (KYC)" key="5">
            <List>
              <List.Item>• We comply with international Anti-Money Laundering (AML) regulations and reserve the right to request identity verification (KYC) documents at any time. KYC will be performed by "CIVIC." Liberland Casino is not responsible for any data breaches originating from this partner.</List.Item>
              <List.Item>• Failure to provide the requested documentation may result in the suspension or termination of your account.</List.Item>
              <List.Item>• Suspicious activity, including but not limited to fraudulent transactions, may be reported to relevant authorities.</List.Item>
            </List>
          </Panel>

          <Panel header="6. Fair Play and Randomness" key="6">
            <List>
              <List.Item>• Liberland Casino ensures fairness using provably fair algorithms and blockchain-based random number generators. The codebase hosted in the InfinityVoid metaverse casino is managed by InfinityVoid, which will be responsible for addressing issues related to randomness.</List.Item>
              <List.Item>• Any attempt to manipulate or exploit the system will result in account suspension and potential legal action.</List.Item>
            </List>
          </Panel>

          <Panel header="7. Bonuses and Promotions" key="7">
            <List>
              <List.Item>• All bonuses and promotions are subject to specific terms and conditions, which will be detailed on our platform.</List.Item>
              <List.Item>• Abuse of bonuses or promotional offers may result in forfeiture of winnings and/or account suspension.</List.Item>
            </List>
          </Panel>

          <Panel header="8. Deposits and Withdrawals" key="8">
            <List>
              <List.Item>• Deposits must be made using supported cryptocurrencies. The minimum deposit amount is $10.</List.Item>
              <List.Item>• Withdrawals are processed within 72 hours. Additional verification may be required for large withdrawals.</List.Item>
              <List.Item>• Transaction fees, if any, will be disclosed before completing deposits or withdrawals.</List.Item>
            </List>
          </Panel>

          <Panel header="9. Privacy Policy" key="9">
            <List>
              <List.Item>• Your personal data is processed in accordance with our Privacy Policy, which is available on our website. We use Plausible for data analytics. Plausible is cookie-free.</List.Item>
              <List.Item>• Industry-standard security measures are implemented to protect your data, but we cannot guarantee absolute security due to inherent risks in online communication.</List.Item>
              <List.Item>• Apexx Prime Group and InfinityVoid may access data from Plausible to support gaming functionalities and analytics.</List.Item>
            </List>
          </Panel>

          <Panel header="10. Prohibited Activities" key="10">
            <List>
              <List.Item>• You agree not to use the Services for any unlawful purposes, including but not limited to fraud, money laundering, or terrorist financing.</List.Item>
              <List.Item>• Using bots, scripts, or other automated tools to manipulate our Services is strictly prohibited.</List.Item>
            </List>
          </Panel>

          <Panel header="11. Liability and Disclaimers" key="11">
            <List>
              <List.Item>• Liberland Casino is not responsible for losses incurred while using our Services. Gambling involves financial risk, and you assume full responsibility for your actions.</List.Item>
              <List.Item>• The Services are provided "as is" without warranties regarding reliability, availability, or accuracy.</List.Item>
            </List>
          </Panel>

          <Panel header="12. Dispute Resolution" key="12">
            <List>
              <List.Item>• In the event of a dispute, please contact our customer support team. We will make every effort to resolve the issue promptly.</List.Item>
              <List.Item>• Unresolved disputes will be subject to the exclusive jurisdiction of the courts of Liberland.</List.Item>
            </List>
          </Panel>

          <Panel header="13. Termination" key="13">
            <List>
              <List.Item>• We reserve the right to suspend or terminate your account at our discretion for violations of these Terms or any applicable laws.</List.Item>
            </List>
          </Panel>

          <Panel header="14. Changes to Terms" key="14">
            <List>
              <List.Item>• We reserve the right to modify these Terms at any time. Changes will be effective upon posting to our platform.</List.Item>
              <List.Item>• Continued use of the Services after changes have been made constitutes acceptance of the updated Terms.</List.Item>
            </List>
          </Panel>

          <Panel header="15. Contact Us" key="15">
            <Paragraph>
              If you have any questions or concerns about these Terms, please contact us at: 
              <a href="mailto:liberlandcasino@ethermail.io">liberlandcasino@ethermail.io</a>
            </Paragraph>
          </Panel>
        </Collapse>

        <Paragraph style={{ textAlign: "center", fontSize: "16px", marginTop: "20px" }}>
          By using Liberland Casino, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
        </Paragraph>
      </Layout.Content>
    </Layout>
  );
};

export default TermsPage;
