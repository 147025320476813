import { useEffect, useState } from 'react'
import { useConnect,useAccount,useContract,useSigner   } from 'wagmi'

import { Cascader,Row,Button,notification, Input,Steps, Select,Radio,Avatar, Badge, Card, Typography, Space, Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Alchemy, Network } from "alchemy-sdk";
import axios from 'axios';
import Web3 from 'web3';
import { useNetwork,useSwitchNetwork  } from 'wagmi'

const { Text, Link } = Typography;

const { Meta } = Card;
const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const config = {
  apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
  network: Network.MATIC_MAINNET,
};

const alchemy = new Alchemy(config);





export function Profile({  }) {
  const { connect, connectors, error, isLoading, pendingConnector } =useConnect()
  const { address, isConnected,  connector: activeConnector, } = useAccount();






  function connection(connector){

    connect( connector );
    
}


  return (

    
    <>
      

    <div className="modalMint">

    <Space  direction='vertical' align='center' style={{marginBottom:"8%"}}>
    <Text style={{color:"#E53F71", fontSize:"18px", textAlign:"center"}}> 
Connect Wallet
</Text>

    <Text style={{color:"#E53F71", fontSize:"14px", textAlign:"center"}}> 
Choose how you want to connect with network.
</Text>
</Space>
   
    {connectors.map((connector) => (
    
<>

{isConnected == true && activeConnector.options.appName == connector.options.appName ? (
  <>

<Card style={{ width: 300, background:'#0A0519', border:"1px solid #1AADB2"}} bodyStyle={{textAlign:"justify"}} >
<Badge.Ribbon text="Connected" style={{poition:'absolute', top:'-25px', right:'-25px'}}/>
      <Meta
    
        avatar={<Avatar size="large" src={connector.options.imageIcon} />}
        title={(
          
          <>
           {connector.options.appName}
  {!connector.ready && ' (unsupported)'}
        {isLoading &&
          connector.id === pendingConnector?.id &&
          ' (connecting)'}
          </>
         )}
        description={connector.options.appSub}
      />
    </Card>


<br/>
</>
):(
  <>



<Card onClick={() => connection({ connector })} style={{ width: 300}} bodyStyle={{textAlign:"justify"}}>
      <Meta
    
        avatar={<Avatar size="large" src={connector.options.imageIcon} />}
        title={(
          <>
           {connector.options.appName}
  {!connector.ready && ' (unsupported)'}
        {isLoading &&
          connector.id === pendingConnector?.id &&
          ' (connecting)'}
          </>
         )}
        description={connector.options.appSub}
      />
    </Card>



<br/>
</>
)}




</>
    ))}

    </div>

{error && <div>{error.message}</div>}



     

      </>

  )
}