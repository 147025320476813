import React from "react";
import { Layout, Typography,Row,Col, Collapse, List, Space, Button } from "antd";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from '@ant-design/icons';
const { Header } = Layout;

const { Title,Text, Paragraph } = Typography;
const { Panel } = Collapse;



const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '100%',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
  };


const ResponsibleGamingPage = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>


<Header style={headerStyle}>

<Row >

    
      <Col flex={2} className="navBarLogo">
  <Space.Compact direction='horizontal'>
    <a href = '/'>
    <img src = "./logoNew.jpg" style={{width:"64px"}} />
    </a>
    <Text style={{fontFamily:"Audiowide", fontSize:"24px",marginLeft:"8px",marginTop:"5px", fontWeight:"200px"}}>Liberaland Casino</Text>
   
</Space.Compact>
</Col>
</Row>
</Header>

      <Layout.Content style={{ marginLeft: "140px",marginRight: "140px" ,marginTop:"12px",marginBottom:"12px" }}>
        <Title level={2} style={{ textAlign: "center" }}>
          Responsible Gaming Tips
        </Title>
        <Paragraph style={{ textAlign: "center", fontSize: "16px" }}>
          At Liberland Casino, we are committed to promoting responsible gaming and ensuring that our players enjoy our services safely and responsibly. Gambling should be a form of entertainment, not a source of stress or financial burden. Below are tips and resources to help you maintain control over your gaming experience:
        </Paragraph>

        <Collapse
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          bordered={false}
          defaultActiveKey={['1']}
          style={{ marginTop: "20px" }}
        >
          <Panel header="1. Set a Budget and Stick to It" key="1">
            <List>
              <List.Item>• Before you start playing, set a gambling budget that you can afford to lose.</List.Item>
              <List.Item>• Never exceed your budget and avoid chasing losses.</List.Item>
            </List>
          </Panel>

          <Panel header="2. Manage Your Time Wisely" key="2">
            <List>
              <List.Item>• Limit the amount of time you spend gambling.</List.Item>
              <List.Item>• Take regular breaks to ensure that gaming does not interfere with your daily responsibilities and relationships.</List.Item>
            </List>
          </Panel>

          <Panel header="3. Understand the Games" key="3">
            <List>
              <List.Item>• Learn the rules and odds of the games you are playing.</List.Item>
              <List.Item>• Make informed decisions and avoid betting on games you do not understand.</List.Item>
            </List>
          </Panel>

          <Panel header="4. Avoid Gambling Under the Influence" key="4">
            <List>
              <List.Item>• Never gamble while under the influence of alcohol, drugs, or when you are feeling stressed or upset.</List.Item>
            </List>
          </Panel>

          <Panel header="5. Use Responsible Gaming Tools" key="5">
            <List>
              <List.Item>• Deposit Limits: Set daily, weekly, or monthly deposit limits to manage your spending.</List.Item>
              <List.Item>• Loss Limits: Define the maximum amount you are willing to lose over a specific time period.</List.Item>
              <List.Item>• Self-Exclusion: Temporarily or permanently exclude yourself from our platform if you feel you need a break.</List.Item>
            </List>
          </Panel>

          <Panel header="6. Recognize Problematic Behavior" key="6">
            <List>
              <List.Item>• Do you gamble to escape stress or other problems?</List.Item>
              <List.Item>• Have you lied to friends or family about your gambling habits?</List.Item>
              <List.Item>• Do you spend more money or time on gambling than you can afford?</List.Item>
            </List>
            <Paragraph>
              If you answer "yes" to any of these questions, you may need to reassess your gambling habits.
            </Paragraph>
          </Panel>

          <Panel header="7. Seek Help When Needed" key="7">
            <List>
              <List.Item>• Gamblers Anonymous (GA): <Link to="https://www.gamblersanonymous.org" target="_blank">www.gamblersanonymous.org</Link></List.Item>
              <List.Item>• BeGambleAware: <Link to="https://www.begambleaware.org" target="_blank">www.begambleaware.org</Link></List.Item>
              <List.Item>• Therapy and Counseling: Seek professional counseling to address gambling-related issues.</List.Item>
            </List>
          </Panel>

          <Panel header="8. Protect Vulnerable Individuals" key="8">
            <List>
              <List.Item>• Ensure that minors do not have access to your gambling account.</List.Item>
              <List.Item>• Use parental control software to block gambling websites on devices accessible to children.</List.Item>
            </List>
          </Panel>
        </Collapse>

        <Paragraph style={{ textAlign: "center", fontSize: "16px", marginTop: "20px" }}>
          At Liberland Casino, we believe that responsible gaming is a shared responsibility. If you need assistance, do not hesitate to contact our support team or the resources listed above.
        </Paragraph>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button type="primary" style={{background:"#3F1A50"}} href="mailto:support@liberlandcasino.com">
            Contact Support
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default ResponsibleGamingPage;
