import React from "react";
import { Layout, Typography, Row, Col, Collapse, List, Space, Button } from "antd";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from '@ant-design/icons';

const { Header } = Layout;
const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '100%',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
};

const PrivacyPolicyPage = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>

      <Header style={headerStyle}>
        <Row>
          <Col flex={2} className="navBarLogo">
            <Space.Compact direction='horizontal'>
              <a href='/'>
                <img src="./logoNew.jpg" style={{ width: "64px" }} alt="Liberland Casino Logo" />
              </a>
              <Text style={{ fontFamily: "Audiowide", fontSize: "24px", marginLeft: "8px", marginTop: "5px", fontWeight: "200px" }}>
                Liberland Casino
              </Text>
            </Space.Compact>
          </Col>
        </Row>
      </Header>

      <Layout.Content style={{ marginLeft: "140px", marginRight: "140px", marginTop: "12px", marginBottom: "12px" }}>
        <Title level={2} style={{ textAlign: "center" }}>
          Liberland Casino Privacy Policy
        </Title>
        <Paragraph style={{ textAlign: "center", fontSize: "16px" }}>
          Effective Date: January 19, 2025
        </Paragraph>
        <Paragraph style={{ textAlign: "center", fontSize: "16px" }}>
          At Liberland Casino ("we," "our," "us"), we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website, mobile application, and Services. By using the Services, you consent to the practices described in this Privacy Policy.
        </Paragraph>

        <Collapse
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          bordered={false}
          defaultActiveKey={['1']}
          style={{ marginTop: "20px" }}
        >
          <Panel header="1. Information We Collect" key="1">
            <List>
              <List.Item>• Personal Information: We collect details like name, email, date of birth, and cryptocurrency wallet addresses.</List.Item>
              <List.Item>• Verification Information: Includes ID documents for KYC compliance via our partner "CIVIC."</List.Item>
              <List.Item>• Usage Data: Collects details like IP address, device type, browser, and activity logs.</List.Item>
              <List.Item>• Financial Data: Includes transaction history, deposits, and withdrawals.</List.Item>
              <List.Item>• Analytics Data: Uses Plausible for privacy-focused analytics without personal data collection.</List.Item>
            </List>
          </Panel>

          <Panel header="2. How We Use Your Information" key="2">
            <List>
              <List.Item>• To provide, maintain, and enhance our Services.</List.Item>
              <List.Item>• To process transactions securely and comply with regulations.</List.Item>
              <List.Item>• To prevent fraud and ensure responsible gaming.</List.Item>
              <List.Item>• To communicate updates, promotions, and support-related information.</List.Item>
              <List.Item>• To analyze user behavior and improve security.</List.Item>
              <List.Item>• To comply with legal and regulatory obligations.</List.Item>
            </List>
          </Panel>

          <Panel header="3. How We Share Your Information" key="3">
            <List>
              <List.Item>• With trusted partners like "CIVIC" for KYC verification and "Plausible" for analytics.</List.Item>
              <List.Item>• When legally required for compliance or regulatory reporting.</List.Item>
              <List.Item>• As part of business transactions such as mergers or acquisitions.</List.Item>
              <List.Item>• With "Apexx Prime Group" and "InfinityVoid" for anonymized data analysis.</List.Item>
              <List.Item>• Through Discord for customer support (please review their privacy policies).</List.Item>
            </List>
          </Panel>

          <Panel header="4. Data Security" key="4">
            <List>
              <List.Item>• We use encryption, firewalls, and security measures to protect user data.</List.Item>
              <List.Item>• While we take precautions, no online system is 100% secure.</List.Item>
            </List>
          </Panel>

          <Panel header="5. Your Rights" key="5">
            <List>
              <List.Item>• **Access & Correction**: You can update your personal details by contacting us.</List.Item>
              <List.Item>• **Data Deletion**: You can request account deletion, subject to legal retention requirements.</List.Item>
              <List.Item>• **Opt-Out**: Unsubscribe from marketing communications at any time.</List.Item>
              <List.Item>• **Additional Rights**: Depending on your jurisdiction, GDPR and other data laws may apply.</List.Item>
            </List>
          </Panel>

          <Panel header="6. Retention of Data" key="6">
            <List>
              <List.Item>• We retain data as necessary for compliance, dispute resolution, and enforcement of terms.</List.Item>
              <List.Item>• KYC data is managed in accordance with **CIVIC's** policies.</List.Item>
            </List>
          </Panel>

          <Panel header="7. Third-Party Links" key="7">
            <List>
              <List.Item>• Our platform may include links to third-party services with separate privacy policies.</List.Item>
              <List.Item>• We encourage users to review the privacy terms of external websites before engaging.</List.Item>
            </List>
          </Panel>

          <Panel header="8. Changes to This Privacy Policy" key="8">
            <List>
              <List.Item>• We may update this policy periodically, with changes taking effect upon posting.</List.Item>
              <List.Item>• Continued use of our Services indicates acceptance of policy updates.</List.Item>
            </List>
          </Panel>

          <Panel header="9. Contact Us" key="9">
            <Paragraph>
              If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:
            </Paragraph>
            <Paragraph>
              📧 Email: <a href="mailto:liberlandcasino@ethermail.io">liberlandcasino@ethermail.io</a>
            </Paragraph>
          </Panel>

        </Collapse>

        <Paragraph style={{ textAlign: "center", fontSize: "16px", marginTop: "20px" }}>
          By using Liberland Casino, you acknowledge that you have read, understood, and agree to this Privacy Policy.
        </Paragraph>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button type="primary" style={{ background: "#3F1A50" }} href="mailto:liberlandcasino@ethermail.io">
            Contact Support
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default PrivacyPolicyPage;
