import { useState, useEffect } from 'react';
import { Col, Row, Button, Spin, Layout, Select, Typography, Space, notification, Input, message } from 'antd';
import { SwapOutlined, LoadingOutlined } from '@ant-design/icons';
import Web3 from 'web3';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import axios from 'axios';
import ethAbi from './ethAbi.json';
import { useAccount, useBalance } from 'wagmi';
import { parseEther } from 'viem';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const supportedCountries = [
  { name: "Brazil", code: "BR" },
  { name: "Bolivia", code: "BO" },
  { name: "Argentina", code: "AR" },
  { name: "Uruguay", code: "UY" },
  { name: "Venezuela", code: "VE" },
  { name: "Ecuador", code: "EC" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Chile", code: "CL" },
  { name: "Honduras", code: "HN" },
  { name: "Guatemala", code: "GT" },
  { name: "Nicaragua", code: "NI" },
  { name: "Panama", code: "PA" },
  { name: "El Salvador", code: "SV" },
  { name: "Costa Rica", code: "CR" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Cuba", code: "CU" },
  { name: "Haiti", code: "HT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Antigua and Aruba", code: "AG" }, // Antigua (AG) and Aruba (AW) are separate countries
  { name: "Saint Lucia", code: "LC" },
  { name: "Colombia", code: "CO" },
  { name: "Mexico", code: "MX" },
  { name: "Suriname", code: "SR" },
  { name: "Guiana", code: "GF" }, // French Guiana is technically an overseas territory
  { name: "Belize", code: "BZ" },
  { name: "Algeria", code: "DZ" },
  { name: "Angola", code: "AO" },
  { name: "Benin", code: "BJ" },
  { name: "Botswana", code: "BW" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cameroon", code: "CM" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "DR Congo", code: "CD" },
  { name: "R Congo", code: "CG" },
  { name: "Ivory Coast", code: "CI" },
  { name: "Djibouti", code: "DJ" },
  { name: "Egypt", code: "EG" },
  { name: "Eritrea", code: "ER" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Ghana", code: "GH" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea Bissau", code: "GW" },
  { name: "Kenya", code: "KE" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Mali", code: "ML" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Namibia", code: "NA" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Rwanda", code: "RW" },
  { name: "Sao Tome", code: "ST" },
  { name: "Senegal", code: "SN" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Sudan", code: "SS" },
  { name: "Sudan", code: "SD" },
  { name: "Swaziland", code: "SZ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Togo", code: "TG" },
  { name: "Tunisia", code: "TN" },
  { name: "Uganda", code: "UG" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
  { name: "Afghanistan", code: "AF" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Bhutan", code: "BT" },
  { name: "Brunei", code: "BN" },
  { name: "Cambodia", code: "KH" },
  { name: "China", code: "CN" },
  { name: "East Timor", code: "TL" },
  { name: "Hong Kong", code: "HK" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Israel", code: "IL" },
  { name: "Japan", code: "JP" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Lebanon", code: "LB" },
  { name: "Macau", code: "MO" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mongolia", code: "MN" },
  { name: "Myanmar", code: "MM" },
  { name: "Nepal", code: "NP" },
  { name: "North Korea", code: "KP" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Philippines", code: "PH" },
  { name: "Qatar", code: "QA" },
  { name: "Russia", code: "RU" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Singapore", code: "SG" },
  { name: "South Korea", code: "KR" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Syria", code: "SY" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Thailand", code: "TH" },
  { name: "Taiwan", code: "TW" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "UAE", code: "AE" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vietnam", code: "VN" },
  { name: "Yemen", code: "YE" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Armenia", code: "AM" },
  { name: "Georgia", code: "GE" },
  { name: "Bosnia", code: "BA" },
  { name: "Moldova", code: "MD" },
  { name: "Albania", code: "AL" },
  { name: "North Macedonia", code: "MK" },
  { name: "Montenegro", code: "ME" },
  { name: "Ukraine", code: "UA" }
];
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "#0A0519"
    }}
    spin
  />
);

function Deposit({ parentCallBack }) {
  const navigate = useNavigate();
  const { chain } = useNetwork();
  const [inputStyleFin, setInputStyleFin] = useState({ textAlign: "start" });
  const [inputStyleMatic, setInputStyleMatic] = useState({ textAlign: "end" });
  const [selectedCoin, setSelectedCoin] = useState("ETH");
  const [selectedCoinRate, setSelectedCoinRate] = useState("1500 TOKEN");
  const [selectedCoinRateFinity, setSelectedCoinRateFinity] = useState("0.0006 ETH");
  const [selectedCoinValue, setSelectedCoinValue] = useState();
  const [finValue, setFinValue] = useState();
  const [validCountry, setValidCountry] = useState(false);
  const [user, setUser] = useState();
  const apikey = process.env.REACT_APP_API_KEY;
  const EthAddress = "0x9E7e513962d064622b5153F84d8BE5D5cF4E11EC";
  const web3 = new Web3(window.ethereum);
  const { address, isConnected } = useAccount();
  const [api, contextHolder] = notification.useNotification();

  const CryptoCall = async () => {
    try {
      
      if (validCountry) {
      const valueTosend = web3.utils.toWei(selectedCoinValue, 'ether');
      if (selectedCoin === "ETH") {
        const contractCall = new web3.eth.Contract(ethAbi, EthAddress);
        const result = await contractCall.methods.depositTransaction().send({ from: address, value: valueTosend });
        
        if (result.status === true) {
          const newData = {
            walletAddress: address && address.toLowerCase(),
            userId: user.id,
            token: selectedCoin,
            amount: selectedCoinValue,
            status: "success",
            apikey: apikey,
            transactionHash: result.transactionHash,
            transactionType: "Deposit"
          };
          saveData(newData);
        }
      }
    }else{
      openNotification("bottom", "Unsupported Country", "Your country is not supported.");

    }
    } catch (error) {
      console.error(error);
    }
  };



  const checkLocation = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/?token=9f783bec44f41c`);
      
      if (response.data) {
        const userCountryCode = response.data.country;
        const isSupported = supportedCountries.some(country => country.code === userCountryCode);
  

        
        if (isSupported) {
          setValidCountry(true);
        }
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };
  

  const saveData = async (data) => {
    try {

    

      if (validCountry) {
        const newitem = { data };
        const res = await axios.post(`https://mint.infinityvoid.io/gameApi/depositToken`, newitem);

        if (res.status === 200) {
          const coinBalData = await axios.get(`https://mint.infinityvoid.io/gameApi/withdrawToken/byUserId/${user.id}`);
          if (coinBalData.data !== "") {
            const balance = Number(coinBalData.data.balance) + Number(finValue);
            const withdrawData = { balance: balance.toString(), apikey: apikey };
            const withdrawRes = await axios.put(`https://mint.infinityvoid.io/gameApi/withdrawToken/${coinBalData.data.id}`, withdrawData);

            if (withdrawRes.status === 200) {
              openNotification("bottom", "Deposited Successfully", "success");
              window.location.reload(true);
            } else {
              openNotification("bottom", "Deposited Failed", "error");
            }
          } else {
            const withdrawData = {
              data: {
                userId: user.id,
                walletAddress: address && address.toLowerCase(),
                balance: finValue,
                apikey: apikey
              }
            };
            const withdrawRes = await axios.post(`https://mint.infinityvoid.io/gameApi/withdrawToken`, withdrawData);
            if (withdrawRes.status === 200) {
              openNotification("bottom", "Deposited Successfully", "success");
              window.location.reload(true);
            } else {
              openNotification("bottom", "Deposited Failed", "error");
            }
          }
        }
      } else {
        openNotification("bottom", "Not Supported Country", "success");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const balance = useBalance({
    address: address,
  });

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (isConnected) {
          const res = await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${address && address.toLowerCase()}`);
          setUser(res.data);
          checkLocation();
          if (!res.data) {
            openNotification('bottomLeft', "User not found", "error");
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUserData();
  }, [address, isConnected]);

  const openNotification = (placement, message, type) => {
    api.open({
      type: type,
      message: message,
      description: '',
      placement
    });
  };

  const handleChange = (value) => {
    setSelectedCoin(value.key);
    if (value.key === "ETH") {
      setSelectedCoinRate("1500 TOKEN");
      setSelectedCoinRateFinity("0.0006 ETH");
      const finValue = 1500 * selectedCoinValue;
      if (selectedCoinValue > 0) {
        setFinValue(finValue.toString());
      }
    }
  };

  const maticChange = (event) => {
    const value = event.target.value;
    setSelectedCoinValue(value);

    if (selectedCoin === "ETH") {
      const finValue = 1500 * value;
      setFinValue(finValue.toString());
      const NewWidth = 100 + finValue.toString().length * 10;
      const NewWidthPx = NewWidth.toString() + "px";
      setInputStyleFin({ width: NewWidthPx < "300px" ? NewWidthPx : "300px" });
    }

    const NewfontSize = 42 - value.length * 1.5;
    const NewfontSizePx = NewfontSize.toString() + "px";
    const NewWidth = 100 + value.length * 10;
    const NewWidthPx = NewWidth.toString() + "px";
    setInputStyleMatic({ width: NewWidthPx < "300px" ? NewWidthPx : "300px", textAlign: "end" });
  };

  const DepositToken = async () => {


    if (isConnected && chain && chain.id === 11155111) {
      try {
        await CryptoCall(parseEther(selectedCoinValue));
      } catch (error) {
        openNotification('bottomLeft', "Please Add Amount First", "error");
        console.error(error);
      }
    } else {
      parentCallBack("openLoginModal");
    }
  };

  const checkTransactionStatus = async (result, tokenValue) => {
    try {
      const txReceipt = await web3.eth.getTransactionReceipt(result.transactionHash);
      if (txReceipt == null) {
        setTimeout(() => checkTransactionStatus(result, tokenValue), 60);
      } else {
        if (txReceipt.status === 1) {
          parentCallBack("stopLoading");
          await CryptoCall(tokenValue);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {contextHolder}
      <Row gutter={24} style={{ marginTop: "10%", marginBottom: "10%" }}>
        <Col className="maticCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }}>
          <Space direction='vertical' size={"middle"}>
            <Text className='textParaHead'>You'll deposit</Text>
            <Space direction='horizontal'>
              <Select
                disabled={true}
                showArrow={false}
                bordered={false}
                labelInValue
                defaultValue={{
                  value: 'ETH',
                  label: 'ETH',
                }}
                onChange={handleChange}
              />
              <input onChange={maticChange} className='Input' placeholder="0.00" value={selectedCoinValue} style={inputStyleMatic} />
            </Space>
            <Space direction='horizontal'>
              <Text className='textPara'>1 {selectedCoin} =</Text>
              <input onChange={maticChange} className='InputA' disabled placeholder={selectedCoinRate} style={{ width: "115px", padding: "8px" }} />
            </Space>
          </Space>
        </Col>
        <Col className='midCol' flex={{ xs: 4, sm: 4, md: 4, lg: 4 }} style={{ textAlign: "center" }}>
          {isMobile ? (
            <SwapOutlined rotate={270} onClick={() => parentCallBack("changeTab")} className='swapIcon' color='#ffffff' />
          ) : (
            <SwapOutlined onClick={() => parentCallBack("changeTab")} className='swapIcon' color='#ffffff' />
          )}
        </Col>
        <Col className="finCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }}>
          <Space direction='vertical' size={"middle"}>
            <Text className='textParaHead'>You'll receive</Text>
            <Space direction='horizontal' size={'small'}>
              <input disabled className='Input' placeholder="0.00" value={finValue} style={inputStyleFin} />
              <Select
                disabled={true}
                showArrow={false}
                bordered={false}
                labelInValue
                defaultValue={{
                  value: 'TOKEN',
                  label: 'TOKEN',
                }}
              />
            </Space>
            <Space direction='horizontal'>
              <Text className='textPara'>1 TOKEN =</Text>
              <input onChange={maticChange} className='InputB' disabled placeholder={selectedCoinRateFinity} style={{ width: "135px", padding: "8px" }} />
            </Space>
          </Space>
        </Col>
      </Row>
      <Button onClick={() => DepositToken()} style={{ height: "40px", borderRadius: "20px" }}>Deposit Amount</Button>
      <br />
    </>
  );
}

export default Deposit;
